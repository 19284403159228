import { types as sdkTypes } from '../util/sdkLoader';
const { UUID } = sdkTypes;

/**
 * Stripe test data
 *
 * This data is for filling up the test values related to Stripe
 * in the Private TEST application to make testing easier.
 */

export const testData = {
  basicTestPaymentMethodToken: 'pm_card_visa',
  basicTestCardToken: 'tok_visa',
  basicTestCardDetails: {
    id: new UUID('test-card'),
    type: 'stripePaymentMethod',
    attributes: {
      type: 'stripe-payment-method/card',
      stripePaymentMethodId: 'test-card',
      card: {
        brand: 'visa',
        last4Digits: '4242',
        expirationMonth: 4,
        expirationYear: 2424,
      },
    },
  },
  address: {
    addressLine1: 'Main Street 123',
    postal: '00100',
    city: 'Helsinki',
    country: 'FI',
    recipientAddressLine1: 'Main Street 123',
    recipientPostal: '00100',
    recipientCity: 'Helsinki',
    recipientCountry: 'FI',
  },
};
